export const REQUIRED_DETAILS_TEXT = "Enter all required details";
export const BOOK_BUTTON_TEXT = "Confirm and Book";
export const CONTACT_INFO_TITLE = "Step 2: Contact Information";
export const CONTACT_INFO_TITLE_NO_STEP = "Your Contact Information";
export const CONTACT_INFO_SUBTITLE =
  "This information is used to notify you about any updates or changes to your trip.";
export const CONTACT_INFO_HELPER_TEXT =
  "You must add one driver and enter your contact information to continue.";
export const UNDER_25_WARNING =
  "This rental car company may charge an underage driver fee at pick-up.";
export const TRAVELER_INFO_TITLE = "Step 1: Driver Information";

export const TREES_MODAL_TITLE = "How it works";
export const TREES_MODAL_SUBTITLE =
  "For every <span class='font-bold'>flight, hotel</span> or <span class='font-bold'>car rental</span> you book, together with our partners, we <span class='font-bold'>plant 4 trees</span> to help with forest restoration programs.";
export const TREES_MODAL_HEADER =
  "When you book, <span class='font-regular'>you plant trees.</span>";
export const TREES_MODAL_LOCATION = "Banff, Alberta";
export const TREES_MODAL_CTA_TEXT = "will be planted for this booking! ";
export const TREES_BOLDED_MODAL_CTA_TEXT = "4 trees ";
export const DETAILS_MODAL_TRIGGER_LABEL = "View Car Rental's Full Details";
