import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import H from "history";

import { CalendarPickerButton } from "./components/CalendarPickerButton";
import {
  DropOffLocationAutocomplete,
  PickUpLocationAutocomplete,
} from "./components/LocationAutocomplete";
import { CarsSearchButton } from "./components/SearchButton";
import { DriverAgeInput } from "./components/DriverAgeInput";
import {
  DropOffTimeSelector,
  PickUpTimeSelector,
} from "./components/TimeSelector";
import { getSelectedOption } from "./components";
import { PATH_AVAILABILITY } from "../../../../utils/paths";
import * as constants from "./constants";
import { transformToStringifiedAvailabilityQuery } from "../../../availability/utils/queryStringHelpers";
import { DesktopCarSearchControlConnectorProps } from "./container";
import "./styles.scss";
import { usePrevious } from "../../../../hooks/usePrevious";
import {
  SwitchButton,
  Icon,
  IconName,
  NotificationBanner,
  BannerSeverity,
  PolicyDetailsModal,
  PolicyModalButton,
} from "halifax";
import {
  CarsTripCategory,
  ModalScreens,
  POLICY_MODAL,
  RecentCarSearch,
  VIEWED_POLICY_MODAL,
} from "redmond";
import { calcMinimumDropOffTime } from "./components/TimeSelector/util";
import {
  useExperiments,
  getExperimentVariant,
  CARS_OFF_HOUR_PICKUP,
  AVAILABLE,
} from "../../../../context/experiments";
import { ClientContext } from "../../../../App";
import { useExperimentIsVariant } from "@capone/experiments";
import { trackEvent } from "../../../../api/v1/analytics/trackEvent";

interface IDesktopCarSearchControlProps
  extends DesktopCarSearchControlConnectorProps {
  className?: string;
  onSearch?: (history: H.History) => void;
  displaySearchOnChange?: boolean;
  showTravelerSelection?: boolean;
  recentSearches?: RecentCarSearch[];
  onRecentSearchClick?: (search: RecentCarSearch) => void;
}

const areAllElementsTruthy = (...elements: any[]) => !elements.some((e) => !e);

export const DesktopCarSearchControl = (
  props: IDesktopCarSearchControlProps
) => {
  const {
    className,
    onSearch,
    dropOffDate,
    dropOffLocation,
    dropOffTime,
    pickUpDate,
    pickUpLocation,
    pickUpTime,
    driverAge,
    displaySearchOnChange,
    setTripCategory,
    tripCategory,
    setDropOffLocation,
    resetFilters,
    recentSearches,
    onRecentSearchClick,
  } = props;
  const [hasChanged, setHasChanged] = React.useState<boolean>(false);
  const isFirstUpdate = React.useRef<boolean>(true);
  const prevDropOffDate = usePrevious(dropOffDate);
  const prevPickUpDate = usePrevious(pickUpDate);
  const prevDropOffTime = usePrevious(dropOffTime);
  const prevPickUpTime = usePrevious(pickUpTime);
  const prevDropOffLocation = usePrevious(dropOffLocation);
  const prevPickUpLocation = usePrevious(pickUpLocation);

  const [isPolicyModalOpen, setIsPolicyModalOpen] = useState(false);

  const { policies } = useContext(ClientContext);

  const isReadyToSearch =
    !!dropOffDate &&
    !!dropOffTime &&
    !!dropOffLocation &&
    !!pickUpDate &&
    !!pickUpTime &&
    !!pickUpLocation &&
    !!driverAge;

  useEffect(() => {
    if (
      areAllElementsTruthy(
        dropOffDate,
        dropOffLocation,
        dropOffTime,
        pickUpDate,
        pickUpLocation,
        pickUpTime
      )
    ) {
      const hasChangedDates =
        dropOffDate !== prevDropOffDate && pickUpDate !== prevPickUpDate;
      const hasChangedLocations =
        dropOffLocation !== prevDropOffLocation &&
        pickUpLocation !== prevPickUpLocation;
      const hasChangedTimes =
        dropOffTime !== prevDropOffTime && pickUpTime !== prevPickUpTime;
      // skip the first update
      if (isFirstUpdate.current) {
        isFirstUpdate.current = false;
      } else if (hasChangedDates || hasChangedLocations || hasChangedTimes) {
        setHasChanged(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dropOffDate,
    dropOffLocation,
    dropOffTime,
    pickUpDate,
    pickUpLocation,
    pickUpTime,
  ]);

  const [dropOffSameAsPickupLocation, setDropOffSameAsPickupLocation] =
    useState(true);
  const [hasMissingSearchInfoError, setHasMissingSearchInfoError] =
    useState(false);

  useEffect(() => {
    if (dropOffSameAsPickupLocation) {
      setTripCategory(CarsTripCategory.SAME_AS_DROP_OFF);
    } else {
      setTripCategory(CarsTripCategory.DIFFERENT_DROP_OFF);
    }
  }, [dropOffSameAsPickupLocation]);

  useEffect(() => {
    if (dropOffLocation && dropOffLocation !== pickUpLocation) {
      setDropOffSameAsPickupLocation(false);
    }
  }, [dropOffLocation]);

  useEffect(() => {
    if (tripCategory === CarsTripCategory.SAME_AS_DROP_OFF)
      setDropOffLocation(pickUpLocation);
  }, [tripCategory, pickUpLocation]);

  const minTime: string | undefined = useMemo(() => {
    if (pickUpDate && dropOffDate && pickUpTime) {
      return calcMinimumDropOffTime(pickUpDate, dropOffDate, pickUpTime);
    }
    return;
  }, [pickUpDate, dropOffDate, pickUpTime]);

  const renderSameDropOffLocationSwitch = () => {
    const handleSetDropoffLocationSameAsPickup = (selected: boolean) => {
      setDropOffSameAsPickupLocation(selected);
      if (pickUpLocation && selected) setDropOffLocation(pickUpLocation);
    };

    return (
      <SwitchButton
        content={constants.DROP_OFF_AT_THE_PICKUP_LOCATION}
        checked={dropOffSameAsPickupLocation}
        onClick={handleSetDropoffLocationSameAsPickup}
      />
    );
  };

  React.useEffect(() => {
    if (isReadyToSearch) {
      setHasMissingSearchInfoError(false);
    }
  }, [
    dropOffDate,
    dropOffTime,
    dropOffLocation,
    pickUpDate,
    pickUpTime,
    pickUpLocation,
    driverAge,
  ]);

  const expState = useExperiments();

  const isCarsOffHourPickup = useMemo(
    () =>
      getExperimentVariant(expState.experiments, CARS_OFF_HOUR_PICKUP) ===
      AVAILABLE,
    [expState.experiments]
  );

  const isPolicyDescriptorsEnabled = useExperimentIsVariant(
    "corp-admin-policy-descriptors",
    "available"
  );

  const handleSearch = (history: H.History) => {
    history.push(
      `${PATH_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
        dropOffDate!,
        dropOffTime!,
        dropOffLocation!,
        pickUpDate!,
        pickUpTime!,
        pickUpLocation!,
        driverAge!
      )}`
    );
    onSearch && onSearch(history);
    resetFilters();
    setHasChanged(false);
  };
  const handleSearchClick = (history: H.History) => {
    isReadyToSearch
      ? handleSearch(history)
      : setHasMissingSearchInfoError(true);
  };

  const onShowPolicyDetailsModal = () => {
    setIsPolicyModalOpen(true);
    trackEvent({
      eventName: VIEWED_POLICY_MODAL,
      properties: {
        type: POLICY_MODAL,
        entry_point: ModalScreens.CARS_SEARCH,
        funnel: "cars",
      },
    });
  };

  return (
    <Box className="car-search-container">
      {isPolicyDescriptorsEnabled && (
        <>
          <PolicyModalButton
            policies={policies}
            onClick={onShowPolicyDetailsModal}
          />
          <PolicyDetailsModal
            policies={policies}
            isOpen={isPolicyModalOpen}
            setIsOpen={setIsPolicyModalOpen}
            productType="car"
          />
        </>
      )}
      <Box className={clsx("car-search-control-root", className)}>
        <Box className="cars-search-first-row">
          <Box className="cars-location-picker-wrapper">
            <PickUpLocationAutocomplete
              className={clsx("pick-up-auto-complete", "b2b")}
              label={constants.PICK_UP_PLACEHOLDER}
              getOptionSelected={getSelectedOption}
              topContent={renderSameDropOffLocationSwitch()}
              customIcon={
                <Icon
                  name={IconName.B2BMapPin}
                  ariaLabel=""
                  aria-hidden={true}
                />
              }
              popperClassName="cars-autocomplete-popper"
              hasMissingSearchInfoError={
                hasMissingSearchInfoError && !pickUpLocation
              }
              recentSearches={recentSearches}
              onRecentSearchClick={
                onRecentSearchClick
                  ? (recentSearch) =>
                      onRecentSearchClick(recentSearch as RecentCarSearch)
                  : undefined
              }
            />
            <DropOffLocationAutocomplete
              className={clsx("drop-off-auto-complete", "b2b")}
              label={constants.DROP_OFF_PLACEHOLDER}
              getOptionSelected={getSelectedOption}
              customIcon={
                <Icon
                  name={IconName.B2BMapPin}
                  ariaLabel=""
                  aria-hidden={true}
                />
              }
              popperClassName="cars-autocomplete-popper"
              hasMissingSearchInfoError={
                hasMissingSearchInfoError &&
                tripCategory !== CarsTripCategory.SAME_AS_DROP_OFF &&
                !dropOffLocation
              }
            />
          </Box>
          <Box className={"date-input"}>
            <CalendarPickerButton
              saveDatesOnClose
              hasMissingSearchInfoError={
                hasMissingSearchInfoError && !dropOffDate && !pickUpDate
              }
            />
          </Box>
        </Box>
        <Box className="car-search-second-row">
          <Box className={"time-picker"}>
            <PickUpTimeSelector increment={isCarsOffHourPickup ? 60 : 30} />
            <DropOffTimeSelector
              minTime={minTime}
              increment={isCarsOffHourPickup ? 60 : 30}
            />
          </Box>
          <Box className={"second-row-second-column"}>
            <DriverAgeInput
              className="driver-age-input"
              hasMissingSearchInfoError={!driverAge}
            />
            <Box>
              {(!displaySearchOnChange || hasChanged) && (
                <CarsSearchButton
                  className={clsx("car-search-control-button", "b2b")}
                  message={constants.SEARCH}
                  onClick={(history) => {
                    handleSearchClick(history);
                  }}
                  enabled={true}
                />
              )}
            </Box>
          </Box>
        </Box>
        {hasMissingSearchInfoError && (
          <Box className="missing-info-search-error-container">
            <NotificationBanner
              className={clsx("missing-info-search-error-banner")}
              label={constants.MISSING_INFO_SEARCH_ERROR}
              severity={BannerSeverity.ERROR}
              icon={<Icon name={IconName.WarningAlert} />}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
