import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import {
  CarDetailsCard,
  ActionButton,
  getPricesWithComma,
  Icon,
  IconName,
  PolicyBanner,
  OutOfPolicyModal,
  BackToTopButton,
} from "halifax";
import { ModalScreens, SelectedTravelOfferScreen } from "redmond";
import {
  getClickCancelOOPModalEvent,
  getClickContinueOOPModalEvent,
  getShowOOPModalEvent,
  isCorpTenant,
  useShowPolicyBanner,
} from "@capone/common";
import clsx from "clsx";
import { MobileShopConnectorProps } from "./container";
import { RewardsAccountSelection } from "../../../rewards/components";
import * as constants from "./constants";
import { CarShopSmallMap } from "../CarShopSmallMap/component";
import { CarPolicyCard } from "../CarPolicyCard/component";
import "./styles.scss";
import { BASE_PATH_HOME, PATH_BOOK } from "../../../../utils/paths";
import { transformToStringifiedShopQuery } from "../../../availability/utils/queryStringHelpers";
import { MobileShopHeader } from "../MobileShopHeader";
import {
  useExperiments,
  getExperimentVariant,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  AVAILABLE,
  TRAVEL_WALLET_CREDITS_EXPERIMENT,
  CARS_CX_V1,
  GLOBAL_MOBILE_NAV_EXPERIMENT,
} from "../../../../context/experiments";
import {
  TravelWalletDetailsBanner,
  TravelWalletDrawer,
} from "../../../travel-wallet/components";
import { getCancellationPolicyInfo } from "../../utils/getCancellationPolicyInfo";
import { config } from "../../../../api/config";
import { trackEvent } from "../../../../api/v1/analytics/trackEvent";
import { ClientContext } from "../../../../App";

export interface IMobileShopProps
  extends MobileShopConnectorProps,
    RouteComponentProps {}

export const MobileShop = (props: IMobileShopProps) => {
  const {
    selectedVehicle,
    carShopError,
    extraInfo,
    carShopQueryParams,
    history,
    resetBookState,
    largestValueAccount,
    carShopCallInProgress,
    credit,
    canEarnRewards,
  } = props;

  const expState = useExperiments();

  const travelWalletOffer = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_OFFER_EXPERIMENT
  );
  const isTravelWalletOfferExperiment = React.useMemo(
    () => travelWalletOffer === AVAILABLE,
    [travelWalletOffer]
  );

  const travelWalletCreditsExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_CREDITS_EXPERIMENT
  );
  const isTravelWalletCreditsExperiment = React.useMemo(
    () => travelWalletCreditsExperiment === AVAILABLE,
    [travelWalletCreditsExperiment]
  );

  const carsCXV1Experiment = getExperimentVariant(
    expState.experiments,
    CARS_CX_V1
  );
  const isCarsCXV1Experiment = React.useMemo(
    () => carsCXV1Experiment === AVAILABLE,
    [carsCXV1Experiment]
  );

  const globalMobileNavExperimentVariant = getExperimentVariant(
    expState.experiments,
    GLOBAL_MOBILE_NAV_EXPERIMENT
  );
  const isGlobalMobileNavExperiment = React.useMemo(
    () => globalMobileNavExperimentVariant === AVAILABLE,
    [globalMobileNavExperimentVariant]
  );

  const [showPolicyModal, setShowPolicyModal] = useState(false);

  const { isAutoApprovalEnabled, policies } = useContext(ClientContext);

  const showEarnEnhancement =
    !!largestValueAccount &&
    !!largestValueAccount.earn.carsMultiplier &&
    canEarnRewards;

  const isInPolicy =
    !!selectedVehicle &&
    "corporateTravel" in selectedVehicle &&
    selectedVehicle.corporateTravel.policyCompliance.isInPolicy;

  const onReserveCar = () => {
    if (isCorpTenant(config.TENANT) && !isInPolicy && !showPolicyModal) {
      setShowPolicyModal(true);
      trackEvent(
        getShowOOPModalEvent(ModalScreens.CARS_SHOP, "cars", modalType)
      );
      return;
    }

    resetBookState();
    if (selectedVehicle) {
      const id = selectedVehicle.id!;
      const params = transformToStringifiedShopQuery({
        vehicleId: id,
        ...carShopQueryParams,
      });
      history.push(`${PATH_BOOK}${params}`);
    } else {
      history.push(`${BASE_PATH_HOME}`);
    }
  };

  const onContinueClick = () => {
    setShowPolicyModal(false);
    trackEvent(
      getClickContinueOOPModalEvent(ModalScreens.CARS_SHOP, "cars", modalType)
    );
    onReserveCar();
  };

  const renderRewardsAccount = () => (
    <Box className="mobile-car-shop-rewards-account-contents">
      <RewardsAccountSelection className="b2b" popoverClassName="b2b" />
    </Box>
  );

  const [showOffer, setShowOffer] = useState(false);

  const modalType = isAutoApprovalEnabled
    ? "out_of_policy_auto"
    : "out_of_policy_24hr_review";

  const showPolicyBanner = useShowPolicyBanner(policies);

  useEffect(() => {
    setShowOffer(
      isTravelWalletOfferExperiment &&
        !!selectedVehicle?.offer &&
        !carShopCallInProgress &&
        (credit &&
        Math.abs(credit.amount.amount) ===
          Math.abs(selectedVehicle?.offer.amount.amount)
          ? true
          : !!selectedVehicle?.offer.shopPageBanner)
    );
  }, [credit, selectedVehicle]);

  useEffect(() => {
    // removes medallia from the fixed bottom that was covering pricing
    if (document && document.getElementById("nebula_div_btn")) {
      document!.getElementById("nebula_div_btn")!.style.display = "none";
    }
    return () => {
      if (document && document.getElementById("nebula_div_btn")) {
        document!.getElementById("nebula_div_btn")!.style.display = "unset";
      }
    };
  }, [document.getElementById("nebula_div_btn")]);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 100);
  }, []);

  const renderShop = () => (
    <Box
      className={clsx("mobile-car-shop-container", config.TENANT, {
        "cars-cx-v1": isCarsCXV1Experiment,
        "global-mobile-nav": isGlobalMobileNavExperiment,
      })}
    >
      <MobileShopHeader />
      {renderRewardsAccount()}
      <Box className="mobile-car-shop-content-wrapper">
        {showOffer && (
          <TravelWalletDetailsBanner
            onDismiss={() => setShowOffer(false)}
            offer={selectedVehicle?.offer!}
            variant="default"
            screen={SelectedTravelOfferScreen.CARS_REVIEW}
            bannerTextType="shop"
          />
        )}
        <Typography className="shop-title">
          {constants.REVIEW_CAR_RENTAL}
        </Typography>
        <Box className="car-details-card-wrapper">
          {selectedVehicle && (
            <CarDetailsCard
              {...selectedVehicle}
              type="content"
              className={clsx("car-shop-car-details", {
                "includes-earn": showEarnEnhancement,
              })}
              isMobile
              hidePriceAndReward
              earnTagContent={
                showEarnEnhancement ? (
                  <>
                    <Icon name={IconName.StarIcon} />
                    <Typography
                      className="earn-tag-text"
                      dangerouslySetInnerHTML={{
                        __html: constants.getEarnTagText(
                          largestValueAccount.earn.carsMultiplier,
                          largestValueAccount.rewardsBalance
                            .currencyDescription ??
                            largestValueAccount.rewardsBalance.currency
                        ),
                      }}
                    />
                  </>
                ) : undefined
              }
              earnTagClassName={showEarnEnhancement ? "b2b" : undefined}
              isCarsCXV1Experiment={isCarsCXV1Experiment}
            />
          )}
        </Box>
        <Box className="car-shop-map-container">
          {selectedVehicle && (
            <CarShopSmallMap
              selectedVehicle={selectedVehicle}
              vendorMessages={extraInfo?.vendorMessages}
              isMobile
            />
          )}
        </Box>
        <CarPolicyCard
          title={constants.CANCELLATION_POLICY}
          description={getCancellationPolicyInfo(selectedVehicle)}
          isMobile
        />
        <CarPolicyCard
          title={constants.IMPORTANT_INFORMATION}
          details={extraInfo?.vendorMessages?.importantInfo?.details.filter(
            (d) => d.title && !d.title?.includes("Collision Damage Insurance")
          )}
          isMobile
        />
        <CarPolicyCard
          title={constants.BOOKING_CONDITIONS}
          details={extraInfo?.vendorMessages?.bookingConditions?.details}
          isMobile
        />
      </Box>
      {isGlobalMobileNavExperiment ? (
        <BackToTopButton bottom={80} />
      ) : undefined}
      {!isGlobalMobileNavExperiment && isTravelWalletCreditsExperiment ? (
        <TravelWalletDrawer />
      ) : undefined}
      <Box className="mobile-car-shop-price-panel">
        {isCorpTenant(config.TENANT) && showPolicyBanner && (
          <PolicyBanner isInPolicy={isInPolicy} />
        )}
        <Box className="price-container">
          <Typography>
            <span className="car-shop-total-price">
              {getPricesWithComma(selectedVehicle?.totalFiatPrice || "")}
            </span>{" "}
            ({selectedVehicle?.days})
            {!!selectedVehicle?.totalRewards && (
              <>
                {" or "}
                <span className="car-shop-total-rewards">
                  {selectedVehicle?.totalRewards}
                </span>
              </>
            )}
          </Typography>
          {isCarsCXV1Experiment && (
            <Typography className="total-subtitle" variant="body2">
              Total including taxes and fees
            </Typography>
          )}
          <ActionButton
            onClick={onReserveCar}
            className={clsx("reserve-button", "b2b")}
            buttonClassName="mobile-car-shop-price-panel-button"
            message={constants.RESERVE(null)}
          />
          <OutOfPolicyModal
            isMobile
            isOpen={showPolicyModal}
            onClose={() => {
              setShowPolicyModal(false);
              trackEvent(
                getClickCancelOOPModalEvent(
                  ModalScreens.CARS_SHOP,
                  "cars",
                  modalType
                )
              );
            }}
            onContinue={onContinueClick}
            isApprovalRequired={
              policies?.settings && policies.settings.isApprovalRequired
            }
          />
        </Box>
      </Box>
    </Box>
  );
  return !carShopError ? (
    <Box className="mobile-car-shop-wrapper">{renderShop()}</Box>
  ) : (
    <p>error</p>
    // <ShopErrorModal />
  );
};
