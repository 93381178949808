import React, { useContext } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { CorpPolicyBanner, Icon, IconName, PolicyDescriptor } from "halifax";
import "./styles.scss";
import { useExperimentIsVariant } from "@capone/experiments";
import { CorporateTravel, FiatPrice } from "redmond/build/common";
import { useShowPolicyBanner } from "@capone/common";
import { SelectedTravelOfferScreen, TravelWalletOffer } from "redmond";
import { ClientContext } from "../../../../App";
import { PriceBreakdown } from "../../../book/components/PriceBreakdownCard";
import * as constants from "./constants";
import { TravelWalletDetailsBanner } from "../../../travel-wallet/components";

interface ICarPaymentSectionProps {
  driverAge: number;
  message: string;
  onClick: () => void;
  corporateTravel: CorporateTravel | undefined;
  limit: FiatPrice | undefined;
  offer?: TravelWalletOffer;
}

export const CarPaymentSection = ({
  driverAge,
  onClick,
  message,
  corporateTravel,
  limit,
  offer,
}: ICarPaymentSectionProps) => {
  const showUnderAgeWarning = driverAge < constants.UNDERAGE_DRIVER_AGE_MIN;

  const isPolicyDescriptorsEnabled = useExperimentIsVariant(
    "corp-admin-policy-descriptors",
    "available"
  );

  const { policies } = useContext(ClientContext);
  const showPolicyBanner = useShowPolicyBanner(policies);

  return (
    <Box className="car-payment-container">
      <CorpPolicyBanner
        variant="base"
        productType="car"
        corporateTravel={showPolicyBanner ? corporateTravel : undefined}
      />
      <Box className="car-payment-content">
        <PriceBreakdown showSummaryItems={false} />
        {offer ? (
          <TravelWalletDetailsBanner
            offer={offer}
            showButton={false}
            className="car-shop-offer"
            screen={SelectedTravelOfferScreen.CARS_REVIEW}
            bannerTextType="shop"
          />
        ) : null}

        {isPolicyDescriptorsEnabled && (
          <PolicyDescriptor
            corporateTravel={corporateTravel}
            productType="car"
            limit={limit}
          />
        )}
        {showUnderAgeWarning && <UnderageWarning />}
        <Button onClick={onClick} className="reserve-button">
          <span
            dangerouslySetInnerHTML={{
              __html: message,
            }}
          />
        </Button>
      </Box>
    </Box>
  );
};

const UnderageWarning = () => (
  <Box className="underage-warning-container">
    <Icon className="info-icon" name={IconName.InfoCircle} />
    <Typography className="info-label">{constants.UNDERAGE_TEXT}</Typography>
  </Box>
);
