import { put, select } from "redux-saga/effects";
import {
  ConnectionResultEnum,
  InvalidEnum,
  PaymentSessionToken,
  TravelWalletOffer,
} from "redmond";
import { getHSPEarnOfferDescription } from "halifax";
import { Payment } from "@b2bportal/purchase-api";
import { actions } from "../../actions";
import { schedulePayment } from "../../../../api/v1/book/book-flow/schedulePayment";
import {
  getOffers,
  getOpaquePayments,
  getPaymentRequestV2,
  getSession,
  getUseV1PurchaseFlow,
} from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import { schedulePaymentV1 } from "../../../../api/v2/book/book-flow/schedulePayment";
import { authorizeEarnOffer } from "../../../../api/v1/travel-wallet/authorizeEarnOffer";

export function* scheduleBookSaga() {
  try {
    const state: IStoreState = yield select();
    const session = getSession(state);
    const version = getUseV1PurchaseFlow(state) ? "v1" : "v0";

    if (!session) {
      throw Error("Session is undefined");
    }

    if (version === "v0") {
      yield schedulePayment(getPaymentRequestV2(state)!);
      yield put(actions.pollConfirmationDetails(session!));
    } else {
      const corporateOutOfPolicyReason = state.carBook.approvalRequestReason;
      const payments = getOpaquePayments(state);
      if (!payments) {
        throw new Error("Invalid Request.");
      }

      const offers: TravelWalletOffer[] | undefined = yield select(getOffers);
      const appliedOfferPayment = payments?.find(
        (payment) => payment.type === Payment.TravelWalletOffer
      );

      const appliedOffer = offers?.find(
        (offer) => offer.id === appliedOfferPayment?.value.offerId
      );

      const hspEarnDescription = getHSPEarnOfferDescription(appliedOffer);

      // authorize hsp earn offer before fulfillment
      if (hspEarnDescription && appliedOffer?.id) {
        yield authorizeEarnOffer({
          earnOfferId: appliedOffer.id,
        });
      }

      const result: string = yield schedulePaymentV1({
        token: session,
        payments: hspEarnDescription
          ? payments.filter(
              (payment) => payment.type !== Payment.TravelWalletOffer // don't include HSP earn offer in payments since it will fail
            )
          : payments,
        corporateOutOfPolicyReason,
      });
      yield put(
        actions.setv1FulfillSession({ value: result } as PaymentSessionToken)
      );

      yield put(
        actions.pollConfirmationDetails({
          value: result,
        } as PaymentSessionToken)
      );
    }

    yield put(actions.setScheduleBookSuccess());
  } catch (e) {
    yield put(
      actions.setScheduleBookFailed({
        Invalid: InvalidEnum.Missing,
        ConnectionResult: ConnectionResultEnum.Invalid,
      })
    );
  }
}
