import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import {
  setContactInfo,
  schedulePriceQuote,
  scheduleBook,
  setSelectedPaymentMethodId,
  setSelectedRewardsAccountReferenceId,
  setPriceQuote,
  fetchApplicableTravelWalletItems,
  setTripPurpose,
} from "../../../actions/actions";
import { IStoreState } from "../../../../../reducers/types";
import { CorpDesktopCarBookWorkflow } from "./component";
import { getCarShopSelectedVehicle } from "../../../../shop/reducer";
import {
  getSelectedDriverAge,
  getIsBookingValid,
  getConfirmationDetails,
  isTravelerStepComplete,
  isContactStepComplete,
  getPriceDifferenceAcknowledged,
  areAllStepsCompletedInCheckout,
  getConfirmationEmail,
  getConfirmationPhoneNumber,
  getPriceQuote,
  getCombinedBookingSteps,
  getPriceQuoteErrors,
  getCardPaymentAccount,
  getIsHertzProvider,
  getHertzLoyaltyRewardsNumber,
  getConfirmationDetailsError,
  getSelectedDriver,
  getPaymentRequestV2,
  getCarAvailabilityRequest,
  getUseV1PurchaseFlow,
  getIsTravelWalletPaymentOnly,
} from "../../../reducer";
import { ICorpCarAvailabilityLineItem } from "../../../../availability/reducer/utils/carAvailabilityHelperFunctions";
import { fetchTravelWalletCreditHistory } from "../../../../travel-wallet/actions/actions";

const mapStateToProps = (state: IStoreState) => ({
  driverAge: getSelectedDriverAge(state),
  selectedVehicle: getCarShopSelectedVehicle(
    state
  ) as ICorpCarAvailabilityLineItem | null,
  combinedBookingSteps: getCombinedBookingSteps(state),
  isBookingValid: getIsBookingValid(state),
  isTravelerStepComplete: isTravelerStepComplete(state),
  isContactStepComplete: isContactStepComplete(state),
  confirmation: getConfirmationDetails(state),
  priceDifferenceAcknowledged: getPriceDifferenceAcknowledged(state),
  allStepsCompleted: areAllStepsCompletedInCheckout(state),
  email: getConfirmationEmail(state),
  phoneNumber: getConfirmationPhoneNumber(state),
  priceQuote: getPriceQuote(state),
  priceQuoteErrors: getPriceQuoteErrors(state),
  cardPaymentAccount: getCardPaymentAccount(state),
  isHertzProvider: getIsHertzProvider(state),
  hertzLoyaltyRewardsNumber: getHertzLoyaltyRewardsNumber(state),
  confirmationDetailsErrors: getConfirmationDetailsError(state),
  driver: getSelectedDriver(state),
  paymentInfo: getPaymentRequestV2(state),
  carAvailabilityRequest: getCarAvailabilityRequest(state),
  useV1PurchaseFlow: getUseV1PurchaseFlow(state),
  isTravelWalletPaymentOnly: getIsTravelWalletPaymentOnly(state),
});

const mapDispatchToProps = {
  setContactInfo,
  schedulePriceQuote,
  scheduleBook,
  setSelectedPaymentMethodId,
  setSelectedRewardsAccountReferenceId,
  setPriceQuote,
  fetchApplicableTravelWalletItems,
  fetchTravelWalletCreditHistory,
  setTripPurpose,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DesktopCarBookWorkflowConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedDesktopCarBookWorkflow = withRouter(
  connector(CorpDesktopCarBookWorkflow)
);
