import { all, put, select } from "redux-saga/effects";
import { EarnResponse, ProductEarnValues, TravelProductEnum } from "redmond";
import { getHSPEarnOfferDescription } from "halifax";
import { IStoreState } from "../../../reducers/types";
import {
  getTotalCreditCardPaymentRequiredInFiatPrice,
  getRewardsAccountsWithPaymentAccounts,
  getOfferToApply,
} from "../reducer";
import { setAllEarnValues } from "../actions/actions";
import Logger from "../../../utils/logger";
import { productToEarn } from "../../../api/v1/rewards/productToEarn";

export function* fetchAllEarnForProductSaga() {
  try {
    const state: IStoreState = yield select();
    const paymentTotal = getTotalCreditCardPaymentRequiredInFiatPrice(state);

    const accountsWithSavedPayment =
      getRewardsAccountsWithPaymentAccounts(state);

    if (!accountsWithSavedPayment.length || !paymentTotal?.value) {
      yield put(setAllEarnValues({}));
      return;
    }

    const offerToApply = getOfferToApply(state);

    const hspEarnOfferDescription = getHSPEarnOfferDescription(offerToApply);

    const responses: ProductEarnValues[] = yield all(
      accountsWithSavedPayment.map((method) => {
        if (!method) return Promise.resolve();

        return productToEarn({
          account: method.accountReferenceId,
          travelProduct: TravelProductEnum.Cars,
          amountUsd: paymentTotal.value,
          earnOfferDescription: hspEarnOfferDescription,
        }).then((earn) => ({
          accountReferenceId: [method.accountReferenceId],
          earnForProduct: {
            ...earn,
            amount: earn.amount,
          },
        }));
      })
    );

    const earnByRewards: { [key: string]: EarnResponse } = responses.reduce(
      (acc, curr) => {
        acc[curr.accountReferenceId] = curr.earnForProduct;
        return acc;
      },
      {} as Record<string, EarnResponse>
    );
    yield put(setAllEarnValues(earnByRewards));
  } catch (e) {
    Logger.debug(e);
  }
}
