import dayjs from "dayjs";
import {
  VehicleAvailability,
  BagSize,
  IPriceRange,
  TransmissionTypeEnum,
  CarsCancellationPolicyEnum,
  PickUpType,
  CorpVehicleAvailability,
} from "redmond";
import {
  GeneralCarType,
  vehicleKindToGeneralCarType,
  ICarCompaniesFilter,
  ISpecifications,
  Specification,
  ICancellationPolicy,
  CancellationPolicy,
  CarFilterTypeV5,
  PassengerFilter,
} from "../index";
import {
  getVehiclePricePerDay,
  vehicleKindToCarFilterTypeV5,
} from "./carAvailabilityHelperFunctions";

export const performCarTypeFilter = (
  vehicle: VehicleAvailability,
  carTypeFilter: GeneralCarType[] | CarFilterTypeV5[]
): boolean => {
  if (carTypeFilter.length > 0) {
    const vehicleType = vehicleKindToGeneralCarType[vehicle.vehicle.kind];
    const v5VehicleTypes =
      vehicleKindToCarFilterTypeV5[vehicle.vehicle.kind] || [];

    if (
      !carTypeFilter.some((typeFilter) =>
        v5VehicleTypes.includes(typeFilter as CarFilterTypeV5)
      ) &&
      !carTypeFilter.some((type) => type === vehicleType)
    ) {
      return false;
    }
  }

  return true;
};

export const performPassengersFilter = (
  vehicle: VehicleAvailability,
  passengerFilter: PassengerFilter[]
): boolean => {
  if (passengerFilter.length > 0) {
    const vehiclePassengers = vehicle.vehicle.passengerCapacity;
    if (
      passengerFilter.includes(PassengerFilter.UpToFive) &&
      vehiclePassengers <= 5
    ) {
      return true;
    }
    if (
      passengerFilter.includes(PassengerFilter.SixOrMore) &&
      vehiclePassengers >= 6
    ) {
      return true;
    }
    return false;
  }
  return true;
};

export const performPriceRangeFilter = (
  vehicle: VehicleAvailability,
  priceRange: IPriceRange | null
): boolean => {
  if (
    priceRange &&
    (getVehiclePricePerDay(vehicle) < priceRange.min ||
      getVehiclePricePerDay(vehicle) > priceRange.max)
  ) {
    return false;
  }

  return true;
};

export const performMaxTotalPriceFilter = (
  vehicle: VehicleAvailability,
  maxTotalPrice: number
): boolean =>
  maxTotalPrice ? vehicle.payNow.fiat.value <= maxTotalPrice : true;

export const performCarCompaniesFilter = (
  vehicle: VehicleAvailability,
  carCompanies: ICarCompaniesFilter,
  hasChangedFilter: boolean
): boolean => {
  const vehicleSupplier = vehicle.supplierRef;

  if (hasChangedFilter) {
    return carCompanies[vehicleSupplier];
  }

  return true;
};

export const performSpecificationsFilter = (
  vehicle: VehicleAvailability,
  specifications: ISpecifications
): boolean => {
  let result = true;

  Object.entries(specifications).forEach(([specification, isSelected]) => {
    if (isSelected) {
      switch (specification as Specification) {
        case Specification.AirConditioning:
          if (!vehicle.vehicle.airConditioning) {
            result = false;
          }
          break;
        case Specification.AutomaticTransmission:
          if (
            vehicle.vehicle.transmissionType !== TransmissionTypeEnum.Automatic
          ) {
            result = false;
          }
          break;
        case Specification.UnlimitedMileage:
          if (!vehicle.hasUnlimitedMileage) {
            result = false;
          }
          break;
        default:
          break;
      }
    }
  });

  return result;
};

export const performCancellationPolicyFilter = (
  vehicle: VehicleAvailability,
  cancellation: ICancellationPolicy,
  includeConditionalCancellation?: boolean
): boolean => {
  let result = true;

  Object.entries(cancellation).forEach(([option, isSelected]) => {
    if (isSelected) {
      switch (option as CancellationPolicy) {
        case CancellationPolicy.FreeCancellation:
          if (
            !includeConditionalCancellation &&
            vehicle.cancellationPolicy.CancellationPolicy !==
              CarsCancellationPolicyEnum.FreeCancellation
          ) {
            result = false;
          } else if (
            includeConditionalCancellation &&
            (vehicle.cancellationPolicy.CancellationPolicy ===
              CarsCancellationPolicyEnum.NonRefundable ||
              dayjs(vehicle.pickUp.time)
                .subtract(
                  vehicle.cancellationPolicy.hoursBeforePickUp ?? 0,
                  "h"
                )
                .isBefore(dayjs()))
          ) {
            result = false;
          }
          break;
        default:
          break;
      }
    }
  });

  return result;
};

export const performPickUpTypeFilter = (
  vehicle: VehicleAvailability,
  pickUpType: PickUpType[]
): boolean => {
  if (pickUpType.length > 0) {
    const vehiclePickUpType = vehicle.pickUp.pickUpInformation?.pickUpType;
    if (vehiclePickUpType && pickUpType.includes(vehiclePickUpType)) {
      return true;
    }
    return false;
  }
  return true;
};

export const performBagsFilter = (
  vehicle: VehicleAvailability,
  minimumBags: number
): boolean => {
  if (minimumBags) {
    const vehicleBagCapacity = vehicle.vehicle.bagCapacity[BagSize.Unspecified] ?? null;
    if (vehicleBagCapacity && minimumBags <= vehicleBagCapacity) {
      return true;
    }
    return false;
  }
  return true;
};
// Corporate

export const performPolicyFilter = (
  vehicle: VehicleAvailability | CorpVehicleAvailability,
  isInPolicyEnabled: boolean
): boolean => {
  if (isInPolicyEnabled) {
    return (
      (vehicle as CorpVehicleAvailability).corporateTravel?.policyCompliance
        .isInPolicy ?? true
    );
  }

  return true;
};
